<template>
  <a style="color: cornflowerblue !important;" class="flex justify-center" target="_blank" href="https://digyglobal.com/">{{$t('powered')}}</a>
</template>

<script>
export default {
  name: "Powered"
}
</script>

<style scoped>

</style>
